import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jwk keyset find`}</strong>{` -- a JWK in a JWK Set`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jwk keyset find <jwks-file> [--kid=<kid>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto jwk keyset find`}</strong>{` command locates the JWK with a key ID matching
`}<inlineCode parentName="p">{`kid`}</inlineCode>{` from the JWK Set stored in `}<inlineCode parentName="p">{`jwks-file`}</inlineCode>{`. The matching JWK is printed to
STDOUT.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`jwks-file`}</inlineCode>{`
File containing a JWK Set`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--kid`}</strong>{`=`}<inlineCode parentName="p">{`kid`}</inlineCode>{`
The key ID of the JWK to locate from the JWK Set. `}<inlineCode parentName="p">{`kid`}</inlineCode>{` is a case-sensitive
string.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      